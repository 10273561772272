import React from "react";
import { SelectedThemeContext } from "utils/theme";
//
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
//
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from "@material-ui/icons/Brightness7";

const useStyles = makeStyles((theme) => ({
    buttonBorder: {
        border: "1px solid",
        borderColor: theme.palette.text.secondary,
        borderRadius: 12,
        padding: theme.spacing(1),
        width: 40,
        height: 40,
    },
}));

function ThemeSwitchButton() {
    const theme = useTheme();
    const classes = useStyles();

    return (
        <SelectedThemeContext.Consumer>
            {(selectedThemeContext) => (
                <Tooltip title="Toggle light/dark theme">
                    <IconButton
                        onClick={selectedThemeContext.toggleTheme}
                        color="primary"
                        className={classes.buttonBorder}>
                        {theme.palette.type === "light" ? <Brightness4Icon /> : <Brightness7Icon />}
                    </IconButton>
                </Tooltip>
            )}
        </SelectedThemeContext.Consumer>
    );
}

export default ThemeSwitchButton;
