import React, { Fragment, useState } from "react";
import { UserContext } from "App";
import { createFAQCategory } from "api/api";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import FAQCategoryForm from "./FAQCategoryForm";
import FAQSearch from "./FAQSearch";
import FAQCategoryPanel from "./FAQCategoryPanel";

const useStyles = makeStyles((theme) => ({
    categoryContainer: {
        marginLeft: "auto",
        marginRight: "auto",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
        gridGap: theme.spacing(3),
        maxWidth: "850px",
    },
    categoryPanel: {
        margin: "auto",
    },
    centerBox: {
        width: "fit-content",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "1rem 0",
    },
}));

export default function FAQ({ categories, setCategories }) {
    const classes = useStyles();
    const [openCategoryForm, setOpenCategoryForm] = useState(false);
    const loading = categories === undefined;

    const createNewCategory = async (category) => {
        return createFAQCategory(category).then((newCategory) => {
            if (newCategory.id) {
                setCategories((prevState) => [...prevState, newCategory]);
            }

            return newCategory;
        });
    };

    return (
        <Fragment>
            <FAQCategoryForm
                open={openCategoryForm}
                handleClose={() => {
                    setOpenCategoryForm(false);
                }}
                handleCreate={createNewCategory}
            />

            <Grid container spacing={3}>
                <Grid item container justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={6} lg={4}>
                        <FAQSearch />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
                        <UserContext.Consumer>
                            {(contextUser) => (
                                <>
                                    {contextUser &&
                                        contextUser.Permissions.GlobalAdministration && (
                                            <Grid item>
                                                <Button
                                                    onClick={() => {
                                                        setOpenCategoryForm(true);
                                                    }}
                                                    variant={"contained"}
                                                    color="primary">
                                                    new category
                                                </Button>
                                            </Grid>
                                        )}
                                </>
                            )}
                        </UserContext.Consumer>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    {loading ? (
                        <Box className={classes.centerBox}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Grid container className={classes.categoryContainer}>
                            {categories.map((category) => (
                                <Grid item key={category.id} className={classes.categoryPanel}>
                                    <FAQCategoryPanel category={category} />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Fragment>
    );
}
