import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    categoryPanel: {
        width: "230px",
        height: "175px",
    },
    categoryPanelLabel: {
        color: theme.palette.background.paper,
        height: "100%",
        alignItems: "normal",
    },
    categoryTitleDivider: {
        backgroundColor: theme.palette.background.paper,
    },
    categoryTitleContainer: {
        height: "50px",
    },
    categoryTitle: {
        fontSize: "1rem",
    },
    categorySubtitle: {
        height: "60%",
        textTransform: "none",
        fontSize: "0.8rem",
    },
}));

function FAQCategoryPanel({ category }) {
    const classes = useStyles();

    return (
        <Button
            component={RouterLink}
            to={`/help/category/${category.id}`}
            variant="contained"
            color="primary"
            classes={{ root: classes.categoryPanel, label: classes.categoryPanelLabel }}
            className={classes.categoryPanel}>
            <Grid container direction="column" spacing={1}>
                <Grid
                    item
                    container
                    direction="column"
                    justifyContent="center"
                    alignContent="center"
                    className={classes.categoryTitleContainer}>
                    <Grid item>
                        <Typography align="center" className={classes.categoryTitle}>
                            {category.name}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <Divider className={classes.categoryTitleDivider} />
                </Grid>
                <Grid item>
                    <Typography align="center" className={classes.categorySubtitle}>
                        {category.description}
                    </Typography>
                </Grid>
            </Grid>
        </Button>
    );
}

export default FAQCategoryPanel;
