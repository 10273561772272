import React from "react";
import { UserContext } from "App";

import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
    panel: {
        margin: "0.5rem 0",
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
        flexBasis: "33.33%",
        flexGrow: 1,
        flexShrink: 0,
        color: theme.palette.primary.main,
        overflowWrap: "break-word",
        textAlign: "start",
    },
    details: {
        justifyContent: "space-between",
        flexDirection: "column",
    },
    answer: {
        whiteSpace: "pre-line",
        overflowWrap: "break-word",
        hyphens: "auto",
    },
    icons: {
        display: "flex",
        justifyContent: "end",
        alignItems: "end",
        gap: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    faqImageContainer: {
        display: "flex",
        justifyContent: "center",
    },
    faqImage: {
        marginLeft: "auto",
        marginRight: "auto",
        maxHeight: "300px",
        maxWidth: "100%",

        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),

        "&:hover": {
            cursor: "zoom-in",
        },
    },
}));

function FAQQuestion({ item, setRemovedQuestion, handleEditOpen, onPreviewOpen }) {
    const classes = useStyles();
    const content = getContent(item.answer);

    function getContent(answer) {
        const imageRegex = new RegExp("\\![\\w.]*\\!", "gi");

        const paragraphs = answer.split(imageRegex).map((par) => par.trim());
        const images = [...answer.matchAll(imageRegex)].map((img) => img[0].slice(1, -1));
        const contentArray = [];

        for (let i = 0; i < paragraphs.length - 1; i++) {
            contentArray.push({ id: `paragraph_${i}`, type: "paragraph", value: paragraphs[i] });
            contentArray.push({ id: `image_${i}`, type: "image", value: images[i] });
        }

        contentArray.push({
            id: `paragraph_${paragraphs.length}`,
            type: "paragraph",
            value: paragraphs[paragraphs.length - 1],
        });

        return contentArray;
    }

    return (
        <Accordion className={classes.panel}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                <UserContext.Consumer>
                    {(contextUser) => (
                        <>
                            {contextUser?.Permissions.GlobalAdministration && (
                                <Box className={classes.icons}>
                                    <Button
                                        color="primary"
                                        size="small"
                                        startIcon={<EditIcon />}
                                        onClick={() => {
                                            handleEditOpen(item);
                                        }}>
                                        Edit
                                    </Button>
                                    <Button
                                        color="primary"
                                        size="small"
                                        startIcon={<DeleteIcon />}
                                        onClick={() => {
                                            setRemovedQuestion(item);
                                        }}>
                                        Delete
                                    </Button>
                                </Box>
                            )}
                        </>
                    )}
                </UserContext.Consumer>

                <Box>
                    {content.map((item) => {
                        switch (item.type) {
                            case "paragraph": {
                                if (item.value.length > 0) {
                                    return (
                                        <Typography
                                            key={item.id}
                                            align="justify"
                                            className={classes.answer}>
                                            {item.value}
                                        </Typography>
                                    );
                                } else {
                                    return null;
                                }
                            }
                            case "image": {
                                return (
                                    <Box
                                        key={item.id}
                                        className={classes.faqImageContainer}
                                        onClick={() => onPreviewOpen(`faq/${item.value}`)}>
                                        <img
                                            src={`faq/${item.value}`}
                                            className={classes.faqImage}
                                        />
                                    </Box>
                                );
                            }
                            default: {
                                return null;
                            }
                        }
                    })}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}

export default FAQQuestion;
