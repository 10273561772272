import React, { Fragment, useEffect, useState } from "react";
import { UserContext } from "App";
import { useParams, useHistory } from "react-router-dom";
import {
    removeFAQQuestion,
    getFAQCategories,
    createFAQCategory,
    getFAQCategoryQuestions,
    createFAQQuestion,
    editFAQQuestion,
} from "api/api";

import FAQQuestion from "./FAQQuestion";
import FAQCategoryForm from "./FAQCategoryForm";
import { FAQ_PAGE_SIZE } from "./FAQUtils";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Confirmation from "../components/confirmation/Confirmation";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FAQForm from "./FAQForm";
import Pagination from "@material-ui/lab/Pagination";
import { Typography } from "@material-ui/core";
import ImagePreview from "containers/components/imagePreview/ImagePreview";

const useStyles = makeStyles((theme) => ({
    categoryItem: {
        height: "5rem",
    },
    centerBox: {
        width: "fit-content",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "1rem 0",
    },
    questionsContainer: {
        minHeight: "5rem",
        padding: "1rem 0",
    },
}));

export default function FAQSingleCategory({
    parentCategories,
    parentEditCategory,
    parentDeleteCategory,
}) {
    const { id } = useParams();
    const categoryId = parseInt(id);
    const classes = useStyles();
    const history = useHistory();
    const [category, setCategory] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [categories, setCategories] = useState(parentCategories);
    const [loading, setLoading] = useState(true);
    const [openQuestionForm, setOpenQuestionForm] = useState(false);
    const [openCategoryForm, setOpenCategoryForm] = useState(false);
    const [openCategoryDelete, setOpenCategoryDelete] = useState(false);
    const [editedQuestion, setEditedQuestion] = useState(null);
    const [removedQuestion, setRemovedQuestion] = useState(null);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewSrc, setPreviewSrc] = useState(null);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(1);

    useEffect(() => {
        setLoading(true);

        getFAQCategoryQuestions(categoryId, { page: page - 1, size: FAQ_PAGE_SIZE }, true).then(
            (response) => {
                setCount(Math.ceil(response.total / FAQ_PAGE_SIZE));
                setQuestions(response.content);
                setLoading(false);
            }
        );
    }, [categoryId, openQuestionForm, openCategoryForm, removedQuestion, page]);

    useEffect(() => {
        if (categories?.length > 0) {
            const cat = categories.find((cat) => {
                return cat.id === categoryId;
            });
            setCategory(cat);
            setPage(1);
        }
    }, [categories, id]);

    useEffect(() => {
        getFAQCategories().then((response) => setCategories(response));
    }, [openCategoryForm]);

    const handleQuestionCreateOpen = () => {
        setEditedQuestion(null);
        setOpenQuestionForm(true);
    };

    const handleQuestionEditOpen = (item) => {
        setEditedQuestion(item);
        setOpenQuestionForm(true);
    };

    const editQuestion = async (question) => {
        return editFAQQuestion(question.id, question).then(() => {
            setEditedQuestion(null);
        });
    };

    const createQuestion = async (newQuestion) => {
        return createFAQQuestion(newQuestion);
    };

    const deleteQuestion = async (question) => {
        return removeFAQQuestion(question.id);
    };

    const createNewCategory = async (category) => {
        return createFAQCategory(category).then((newCategory) => {
            if (newCategory.id) {
                setCategories((prevState) => [...prevState, newCategory]);
            }

            return newCategory;
        });
    };

    const editCategory = async (category) => {
        parentEditCategory(category);
    };

    const deleteCategory = async (removedCategory) => {
        parentDeleteCategory(removedCategory);
    };

    const handleChangePagination = (event, value) => {
        setPage(value);
    };

    const handleSelectChange = (event) => {
        history.push({ pathname: `/help/category/${event.target.value}` });
    };

    const handlePreviewOpen = (src) => {
        setPreviewSrc(src);
        setPreviewOpen(true);
    };

    const handlePreviewClose = () => {
        setPreviewOpen(false);
        setPreviewSrc(null);
    };

    return (
        <Fragment>
            <FAQForm
                open={openQuestionForm}
                question={editedQuestion}
                categories={categories}
                categoriesLoading={loading}
                createCategory={createNewCategory}
                handleClose={() => setOpenQuestionForm(false)}
                handleCreate={createQuestion}
                handleEdit={editQuestion}
            />
            <FAQCategoryForm
                open={openCategoryForm}
                category={category}
                handleClose={() => {
                    setOpenCategoryForm(false);
                }}
                handleEdit={editCategory}
                handleCreate={createNewCategory}
            />
            <Confirmation
                open={!!removedQuestion}
                onConfirm={() => deleteQuestion(removedQuestion)}
                onClose={() => setRemovedQuestion(null)}>
                Are you sure you want to remove{" "}
                {removedQuestion ? "question " + removedQuestion.question : "this question"}?
            </Confirmation>
            <Confirmation
                open={!!openCategoryDelete}
                onConfirm={() => deleteCategory(category)}
                onClose={() => setOpenCategoryDelete(false)}>
                Are you sure you want to remove{" "}
                {category ? "category " + category.name : "this category"}?
            </Confirmation>
            <ImagePreview open={previewOpen} onClose={handlePreviewClose} imageSrc={previewSrc} />

            <Grid container spacing={2}>
                <Grid item container justifyContent="space-between" alignItems="center" spacing={2}>
                    <FormControl>
                        <Select
                            native
                            value={categoryId}
                            onChange={handleSelectChange}
                            inputProps={{
                                name: "Category",
                            }}>
                            {categories?.map((category) => (
                                <option key={category.id} value={category.id}>
                                    {category.name}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                    <UserContext.Consumer>
                        {(contextUser) => (
                            <>
                                {contextUser && contextUser.Permissions.GlobalAdministration && (
                                    <>
                                        <Grid item>
                                            <Grid container justifyContent="flex-end" spacing={2}>
                                                {questions?.length === 0 && (
                                                    <Grid item>
                                                        <Button
                                                            onClick={() => {
                                                                setOpenCategoryDelete(true);
                                                            }}
                                                            variant={"contained"}
                                                            color="primary">
                                                            remove category
                                                        </Button>
                                                    </Grid>
                                                )}
                                                <Grid item>
                                                    <Button
                                                        onClick={() => {
                                                            setOpenCategoryForm(true);
                                                        }}
                                                        variant={"contained"}
                                                        color="primary">
                                                        edit category
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        onClick={() => {
                                                            setOpenQuestionForm(true);
                                                            handleQuestionCreateOpen();
                                                        }}
                                                        variant={"contained"}
                                                        color="primary">
                                                        new question
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </>
                        )}
                    </UserContext.Consumer>
                </Grid>
            </Grid>
            {loading ? (
                <Box className={classes.centerBox}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Grid
                        container
                        className={classes.questionsContainer}
                        justifyContent="flex-end">
                        {questions?.map((question) => (
                            <Grid item xs={12} key={question.id}>
                                <FAQQuestion
                                    item={question}
                                    setRemovedQuestion={setRemovedQuestion}
                                    handleEditOpen={handleQuestionEditOpen}
                                    onPreviewOpen={handlePreviewOpen}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Grid container className={classes.questionsContainer} justifyContent="center">
                        {questions?.length > 0 ? (
                            <Pagination
                                count={count}
                                page={page}
                                onChange={handleChangePagination}
                                color="primary"
                            />
                        ) : (
                            <Typography>No questions in this category</Typography>
                        )}
                    </Grid>
                </>
            )}
        </Fragment>
    );
}
