import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
    dialog: {
        padding: theme.spacing(1),
    },
    content: {},
}));

const schema = yup
    .object({
        category: yup.object({
            id: yup.number().required(),
            name: yup.string().required(),
        }),
        question: yup.string().required(),
        answer: yup.string().required(),
    })
    .required();

export default function FAQForm({
    open,
    question,
    categories,
    categoriesLoading,
    createCategory,
    handleClose,
    handleEdit,
    handleCreate,
}) {
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        control,
        formState: { errors, isValid, isSubmitting },
    } = useForm({
        defaultValues: {
            category: { id: undefined, name: "" },
            question: "",
            answer: "",
        },
        resolver: yupResolver(schema),
    });
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [autocompleteLoading, setAutocompleteLoading] = useState(false);
    const loading = categoriesLoading || autocompleteLoading;

    useEffect(() => {
        if (question != null) {
            setValue("question", question.question);
            setValue("answer", question.answer);
            setValue(
                "category",
                categories.find((category) => category.id === question.category_id)
            );
        } else {
            reset();
        }
    }, [question, open]);

    const onCancel = () => {
        reset();
        handleClose();
    };

    const onSubmit = (data) => {
        if (question) {
            handleEdit({
                id: question.id,
                category_id: data.category.id,
                question: data.question,
                answer: data.answer,
            }).then(() => {
                onCancel();
            });
        } else {
            handleCreate(data).then(() => {
                onCancel();
            });
        }
    };

    const getCategoryLabel = (option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
            return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
            return option.inputValue;
        }
        // Regular option
        return option.name;
    };

    const handleCategory = async (newValue) => {
        if (typeof newValue === "string") {
            return newValue;
        } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setAutocompleteLoading(true);

            const newCategory = await createCategory({
                category: newValue.inputValue,
            });

            setAutocompleteLoading(false);
            return newCategory;
        } else {
            return newValue;
        }
    };

    const filterCategories = (options, params) => {
        const filtered = filter(options, params);

        if (params.inputValue !== "") {
            filtered.push({
                id: undefined,
                inputValue: params.inputValue,
                name: `Create "${params.inputValue}" category`,
            });
        }

        return filtered;
    };

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            PaperProps={{
                className: classes.dialog,
            }}>
            <DialogTitle>{question ? "Edit" : "Create"} FAQ</DialogTitle>

            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent className={classes.content}>
                    <Grid container spacing={2} direction="column">
                        <Grid item>
                            <Controller
                                render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                        id="category"
                                        freeSolo
                                        disableClearable
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        options={categories}
                                        getOptionLabel={getCategoryLabel}
                                        renderOption={(option) => option.name}
                                        disabled={!!question}
                                        loading={loading}
                                        onChange={async (event, item) =>
                                            onChange(await handleCategory(item))
                                        }
                                        value={value}
                                        filterOptions={filterCategories}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Category"
                                                variant="outlined"
                                                error={!!errors.category}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: "search",
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {loading ? (
                                                                <CircularProgress
                                                                    color="inherit"
                                                                    size={20}
                                                                />
                                                            ) : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                )}
                                onChange={([, data]) => data}
                                name={"category"}
                                control={control}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                {...register("question")}
                                id="question"
                                label="Question"
                                variant="outlined"
                                fullWidth
                                error={!!errors?.question}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                {...register("answer")}
                                id="answer"
                                label="Answer"
                                variant="outlined"
                                fullWidth
                                multiline={true}
                                error={!!errors?.answer}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onCancel}>
                        cancel
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={!isValid || isSubmitting}>
                        {question ? "Update" : "Create"}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
