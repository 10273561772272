import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { UserContext } from "App";

function ProtectedRoute({ isAllowed, redirectPath = "/unauthorized", children }) {
    const userContext = useContext(UserContext);

    if (userContext === undefined) {
        // Prevent redirect before user information is fetched
        return null;
    }

    if (!userContext) {
        return <Redirect to="/login" />;
    }

    if (!isAllowed) {
        return (
            <Redirect
                to={{
                    pathname: redirectPath,
                    state: { unauthorized: true },
                }}
            />
        );
    }

    return <>{children}</>;
}

export default ProtectedRoute;
