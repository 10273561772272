import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    closeButton: {
        color: "white",
    },
    previewToolbar: {
        position: "absolute",
        width: "100%",
        height: "56px",
        top: "0",
        backgroundColor: "rgba(0,0,0,.5)",
        display: "flex",
        justifyContent: "flex-end",
        padding: theme.spacing(1),
    },
    previewContainer: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",

        marginTop: "28px",
    },
    imagePreview: {
        objectFit: "contain",
        maxHeight: "85vh",
        maxWidth: "95vw",
    },
}));

export default function ImagePreview({ open, imageSrc, onClose }) {
    const classes = useStyles();

    return (
        <Modal open={open} onClose={onClose} aria-label="Image preview modal" closeAfterTransition>
            <Fade in={open}>
                <Box>
                    <Box
                        alignItems="center"
                        justifyContent="center"
                        gap={2}
                        className={classes.previewContainer}>
                        <Box component="img" src={imageSrc} className={classes.imagePreview} />
                    </Box>
                    <Box className={classes.previewToolbar}>
                        <IconButton onClick={onClose} disableRipple className={classes.closeButton}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}
