import React, { useEffect, useState, useMemo, useCallback } from "react";
import { SelectedThemeContext, createBaseThemeAttributes } from "utils/theme";

import { ThemeProvider } from "@material-ui/core/styles";
import createTheme from "@material-ui/core/styles/createTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const acceptedThemes = ["light", "dark"];

function ThemeWrapper({ children }) {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)", { noSsr: true });
    const userSavedTheme = localStorage.getItem("theme");
    const getThemePreference = useCallback(() => {
        if (userSavedTheme && acceptedThemes.includes(userSavedTheme)) {
            return userSavedTheme;
        } else {
            return prefersDarkMode ? "dark" : "light";
        }
    }, [userSavedTheme, prefersDarkMode]);
    const [selectedTheme, setSelectedTheme] = useState(getThemePreference(prefersDarkMode));

    const colorMode = useMemo(
        () => ({
            toggleTheme: () => {
                setSelectedTheme((prevMode) => {
                    const nextMode = prevMode === "light" ? "dark" : "light";

                    localStorage.setItem("theme", nextMode);
                    return nextMode;
                });
            },
        }),
        []
    );

    const theme = useMemo(() => {
        return createTheme(createBaseThemeAttributes(selectedTheme));
    }, [selectedTheme]);

    useEffect(() => {
        const themePreference = getThemePreference(prefersDarkMode);
        const htmlTag = document.documentElement;

        setSelectedTheme(themePreference);
        htmlTag.className = `theme-${themePreference}`;
    }, [prefersDarkMode, userSavedTheme, getThemePreference]);

    return (
        <SelectedThemeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </SelectedThemeContext.Provider>
    );
}

export default ThemeWrapper;
