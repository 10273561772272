import React, { useState, useEffect } from "react";
import { useHistory, Link as RouterLink } from "react-router-dom";
import useQuery from "hooks/useQuery";
import { getFAQ, editFAQQuestion, removeFAQQuestion } from "api/api";
import { FAQ_PAGE_SIZE } from "./FAQUtils";

import Confirmation from "containers/components/confirmation/Confirmation";
import FAQForm from "./FAQForm";
import FAQSearch from "./FAQSearch";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Pagination from "@material-ui/lab/Pagination";
import Typography from "@material-ui/core/Typography";
import { Link } from "@material-ui/core";
import FAQQuestion from "./FAQQuestion";

const useStyles = makeStyles((theme) => ({
    categoryName: {
        color: theme.palette.primary.main,
        fontSize: "0.8rem",
        fontWeight: 500,
    },
    categoryReturn: {
        fontSize: "0.8rem",
    },
    centerBox: {
        width: "fit-content",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "1rem 0",
    },
    resultsHeader: {
        fontSize: "1.2rem",
    },
}));

function FAQSearchResult({ categories }) {
    const classes = useStyles();
    const query = useQuery();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [pageContent, setPageContent] = useState([]);
    const [totalQuestions, setTotalQuestions] = useState(undefined);
    const [openQuestionForm, setOpenQuestionForm] = useState(false);
    const [editedQuestion, setEditedQuestion] = useState(null);
    const [removedQuestion, setRemovedQuestion] = useState(null);
    const [fetchTrigger, setFetchTrigger] = useState(false);
    const totalPages = Math.ceil(totalQuestions / FAQ_PAGE_SIZE);
    const page = parseInt(query.get("page")) || 1;

    useEffect(() => {
        setLoading(true);

        getFAQ({ q: query.get("query"), page: page - 1, size: FAQ_PAGE_SIZE }, true).then((res) => {
            setLoading(false);
            setPageContent(res.content);
            setTotalQuestions(res.total);
            setFetchTrigger(false);
        });
    }, [query, page, fetchTrigger]);

    useEffect(() => {
        if (!totalPages) return;

        if (page < 1) {
            setPage(1);
            return;
        }
        if (page > totalPages) {
            setPage(totalPages);
            return;
        }
    }, [page, totalPages]);

    function setPage(newPage) {
        const params = new URLSearchParams();

        params.set("query", query.get("query"));
        params.set("page", newPage);
        history.replace({
            pathname: "/help/search",
            search: params.toString(),
        });
    }

    const editQuestion = async (question) => {
        return editFAQQuestion(question.id, question).then((editedQuestion) => {
            setFetchTrigger(true);
            return editedQuestion;
        });
    };

    const deleteQuestion = async (question) => {
        return removeFAQQuestion(question.id).then(() => {
            setFetchTrigger(true);
        });
    };

    const handleQuestionEditOpen = (item) => {
        setEditedQuestion(item);
        setOpenQuestionForm(true);
    };

    return (
        <>
            <FAQForm
                open={openQuestionForm}
                question={editedQuestion}
                categories={categories}
                categoriesLoading={categories === undefined}
                handleClose={() => setOpenQuestionForm(false)}
                handleEdit={editQuestion}
            />
            <Confirmation
                open={!!removedQuestion}
                onConfirm={() => deleteQuestion(removedQuestion)}
                onClose={() => setRemovedQuestion(null)}>
                Are you sure you want to remove{" "}
                {removedQuestion ? "question " + removedQuestion.question : "this question"}?
            </Confirmation>

            <Grid container spacing={3}>
                <Grid item container xs={12} justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={6} lg={4}>
                        <FAQSearch />
                    </Grid>
                </Grid>

                <Grid item container xs={12} spacing={1}>
                    <Grid item xs={12}>
                        <Link
                            component={RouterLink}
                            to={"/help"}
                            className={classes.categoryReturn}>
                            Return to categories
                        </Link>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component="h3" className={classes.resultsHeader}>
                            Search results
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={2}>
                        {loading ? (
                            <Box className={classes.centerBox}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Grid item xs={12}>
                                {totalQuestions === 0 ? (
                                    <Grid item>
                                        <Box className={classes.centerBox}>
                                            <Typography>No questions found</Typography>
                                        </Box>
                                    </Grid>
                                ) : (
                                    <Grid container spacing={2}>
                                        {pageContent.map((question) => {
                                            return (
                                                <Grid item xs={12} key={question.id}>
                                                    <Typography
                                                        component="span"
                                                        className={classes.categoryName}>
                                                        {
                                                            categories?.find(
                                                                (category) =>
                                                                    category.id ===
                                                                    question.category_id
                                                            ).name
                                                        }
                                                    </Typography>
                                                    <Box>
                                                        <FAQQuestion
                                                            item={question}
                                                            setRemovedQuestion={setRemovedQuestion}
                                                            handleEditOpen={handleQuestionEditOpen}
                                                        />
                                                    </Box>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                )}
                            </Grid>
                        )}

                        {totalQuestions !== 0 && (
                            <Grid item container xs={12} justifyContent="center">
                                <Grid item>
                                    <Pagination
                                        count={totalPages}
                                        page={page}
                                        onChange={(event, value) => setPage(value)}
                                        color="primary"
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default FAQSearchResult;
