import React from "react";
import MenuButton from "../menuItem/MenuButton";
import MenuLink from "../menuItem/MenuLink";
import LoggedAs from "containers/components/misc/LoggedAs";
import { STATE } from "../autoSuggest/UserStateAutoSuggest";
import Version from "containers/components/misc/Version";
import { assertUserPermissions } from "utils/routePermissions";
import ThemeSwitchButton from "../themeSwitchButton/ThemeSwitchButton";
//
import useTheme from "@material-ui/core/styles/useTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
//
import HomeIcon from "@material-ui/icons/Home";
import TableChartIcon from "@material-ui/icons/TableChart";
import LockIcon from "@material-ui/icons/Lock";
import NotificationsPausedIcon from "@material-ui/icons/NotificationsPaused";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import TTMSLogo from "../../components/logo/TTMSLogo";
import RoomIcon from "@material-ui/icons/Room";
import LiveHelp from "@material-ui/icons/LiveHelp";

const drawerWidth = "240px";

const useStyles = makeStyles((theme) => ({
    drawer: {
        [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0,
            boxShadow: "4px 0px 6px rgba(0, 0, 0, 0.16)",
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
    drawerToggleContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginLeft: theme.spacing(2),

        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    versionItem: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        padding: "4px",
    },
}));

function SideMenu(props) {
    const { user, mobileOpen } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

    const onLogout = props.onLogoutHandler;
    const onLogin = props.onLoginHandler;
    const handleDrawerToggle = props.handleDrawerToggle;

    return (
        <nav className={classes.drawer}>
            <Drawer
                variant={isSmUp ? "permanent" : "temporary"}
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                    paper: classes.drawerPaper,
                }}
                ModalProps={{
                    keepMounted: true,
                }}>
                <Box className={classes.drawerToggleContainer}>
                    <ThemeSwitchButton />

                    <IconButton onClick={handleDrawerToggle}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Box>

                <Divider />

                <div className="brand-container">
                    <TTMSLogo className="brand-logo" />
                    <Typography component={isSmUp ? "h1" : "span"} className="brand-title">
                        TimeSheets
                    </Typography>
                </div>

                <Divider />

                <List
                    id="menu"
                    component="menu"
                    className="main-menu"
                    aria-labelledby="nested-list-subheader">
                    {isSmUp && (
                        <ListItem>
                            <ThemeSwitchButton />
                        </ListItem>
                    )}

                    <LoggedAs user={user} />

                    {user && user.State === STATE.ACTIVE && (
                        <MenuLink
                            to="/"
                            name="Home"
                            handler={handleDrawerToggle}
                            icon={<HomeIcon />}
                        />
                    )}
                    {assertUserPermissions("/users", user?.Permissions) && (
                        <MenuLink
                            to="/users"
                            name="Users"
                            handler={handleDrawerToggle}
                            icon={<PersonAddIcon />}
                        />
                    )}
                    {assertUserPermissions("/groups", user?.Permissions) && (
                        <MenuLink
                            to="/groups"
                            name="Groups"
                            handler={handleDrawerToggle}
                            icon={<PersonAddIcon />}
                        />
                    )}
                    {assertUserPermissions("/access-requests", user?.Permissions) && (
                        <MenuLink
                            to="/access-requests"
                            name="Access requests"
                            handler={handleDrawerToggle}
                            icon={<AssignmentIndIcon />}
                        />
                    )}
                    {assertUserPermissions("/overtime", user?.Permissions) && (
                        <MenuLink
                            to="/overtime"
                            name="Overtime requests"
                            handler={handleDrawerToggle}
                            icon={<AssignmentIndIcon />}
                        />
                    )}
                    {assertUserPermissions("/projects", user?.Permissions) && (
                        <MenuLink
                            to="/projects"
                            name="Projects"
                            handler={handleDrawerToggle}
                            icon={<AssignmentIcon />}
                        />
                    )}
                    {assertUserPermissions("/reports/advanced", user?.Permissions) && (
                        <MenuLink
                            to="/reports/advanced"
                            name="Advanced report"
                            handler={handleDrawerToggle}
                            icon={<TableChartIcon />}
                        />
                    )}
                    {assertUserPermissions("/reports/presence", user?.Permissions) && (
                        <MenuLink
                            to="/reports/presence"
                            name="Presence report"
                            handler={handleDrawerToggle}
                            icon={<TableChartIcon />}
                        />
                    )}
                    {assertUserPermissions("/reports/projects", user?.Permissions) && (
                        <MenuLink
                            to="/reports/projects"
                            name="Project report"
                            handler={handleDrawerToggle}
                            icon={<TableChartIcon />}
                        />
                    )}
                    {assertUserPermissions("/reports/users", user?.Permissions) && (
                        <MenuLink
                            to="/reports/users"
                            name="Users report"
                            handler={handleDrawerToggle}
                            icon={<TableChartIcon />}
                        />
                    )}
                    {assertUserPermissions("/reports/customer", user?.Permissions) && (
                        <MenuLink
                            to="/reports/customer"
                            name="Customer report"
                            icon={<TableChartIcon />}
                        />
                    )}
                    {assertUserPermissions("/lock", user?.Permissions) && (
                        <MenuLink
                            to="/lock"
                            name="Lock worklogs"
                            handler={handleDrawerToggle}
                            icon={<LockIcon />}
                        />
                    )}
                    {assertUserPermissions("MailModule", user?.Permissions) && (
                        <MenuLink
                            to="/mail"
                            name="Notification manager"
                            handler={handleDrawerToggle}
                            icon={<NotificationsPausedIcon />}
                        />
                    )}
                    {assertUserPermissions("/locations", user?.Permissions) && (
                        <MenuLink
                            to="/locations"
                            name="Locations"
                            handler={handleDrawerToggle}
                            icon={<RoomIcon />}
                        />
                    )}
                    {user && (
                        <MenuLink
                            to="/help"
                            name="FAQ"
                            handler={handleDrawerToggle}
                            icon={<LiveHelp />}
                        />
                    )}

                    {user ? (
                        <MenuButton
                            name="Logout"
                            icon={<PowerSettingsNewIcon />}
                            handler={onLogout}
                        />
                    ) : (
                        <MenuButton name="Login" icon={<ExitToAppIcon />} handler={onLogin} />
                    )}

                    <ListItem className={classes.versionItem}>
                        <Version />
                    </ListItem>
                </List>
            </Drawer>
        </nav>
    );
}

export default SideMenu;
