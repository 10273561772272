import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import ControlledAutocomplete from "containers/components/form/ControlledAutocomplete";
import { getFAQCategoryPermissions } from "api/api";

const useStyles = makeStyles((theme) => ({
    dialog: {
        padding: theme.spacing(1),
    },
}));

const schema = yup
    .object({
        name: yup.string().required(),
        description: yup.string().optional(),
        permissions: yup.array(yup.string()).required(),
    })
    .required();

export default function FAQCategoryForm({ open, category, handleClose, handleCreate, handleEdit }) {
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        control,
        formState: { errors, isValid, isSubmitting },
    } = useForm({
        defaultValues: {
            name: "",
            description: "",
            permissions: [],
        },
        resolver: yupResolver(schema),
    });
    const classes = useStyles();
    const [categoryPermissions, setCategoryPermissions] = useState([]);
    const loading = categoryPermissions.length === 0;

    useEffect(() => {
        const fetchPermissions = () => {
            getFAQCategoryPermissions(true).then((data) => {
                setCategoryPermissions(data);
            });
        };

        fetchPermissions();
    }, []);

    useEffect(() => {
        if (category != null) {
            setValue("name", category.name);
            setValue("description", category.description);
            setValue("permissions", category.permissions);
        }
    }, [category, setValue]);

    const onCancel = () => {
        reset();
        handleClose();
    };

    const onSubmit = (data) => {
        if (category?.id) {
            const newObject = {
                ...data,
                id: category.id,
            };
            handleEdit(newObject).then(() => {
                onCancel();
            });
        } else {
            handleCreate(data).then(() => {
                onCancel();
            });
        }
    };

    const handlePermissionChange = (newValue) => {
        return newValue;
    };

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={open}
            onClose={handleClose}
            PaperProps={{
                className: classes.dialog,
            }}>
            <DialogTitle>{category ? "Edit" : "Create"} FAQ Category</DialogTitle>

            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent className={classes.content}>
                    <Grid container spacing={2} direction="column">
                        <Grid item>
                            <TextField
                                {...register("name")}
                                id="name"
                                label="Name"
                                variant="outlined"
                                fullWidth
                                error={!!errors?.name}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                {...register("description")}
                                id="description"
                                label="Description"
                                variant="outlined"
                                fullWidth
                                error={!!errors?.description}
                            />
                        </Grid>
                        <Grid item>
                            <ControlledAutocomplete
                                name="permissions"
                                label="Required permission"
                                options={categoryPermissions}
                                multiple={true}
                                getOptionLabel={(option) => {
                                    return option;
                                }}
                                renderOption={(option) => {
                                    return option;
                                }}
                                handleChange={handlePermissionChange}
                                control={control}
                                loading={loading}
                                error={errors?.permissions}
                                type="text"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onCancel}>
                        cancel
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={!isValid || isSubmitting}>
                        {category ? "Update" : "Create"}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
