/* eslint-disable react/prop-types, react/jsx-handler-names */

import React, { Fragment } from "react";
import AsyncSelect from "react-select/async";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { components } from "react-select";

import "./AutoSuggest.scss";
import { Tooltip } from "@material-ui/core";
import { debounce } from "utils/debounce";

const CrossIcon = components.CrossIcon;
const DownChevron = components.DownChevron;

const styles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: "flex",
        padding: "16px 12px 4px",
        transition: "opacity .15s ease-in-out",
        height: "36px",
    },
    inputDisabled: {
        opacity: "0.5",
    },
    valueContainer: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        overflow: "hidden",
    },
    indicatorsContainer: {
        display: "flex",
        cursor: "pointer",
    },
    indicator: {
        borderRadius: "50%",
        backgroundColor: theme.palette.primary.main,
        padding: "0px",
        marginLeft: "4px",
        color: theme.palette.background.default,
        height: "20px",
    },
    chip: {
        margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
    },
    chipFocused: {},
    noOptionsMessage: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    singleValue: {
        fontSize: 14,
        overflow: "hidden",
        maxHeight: "100%",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    menuList: {
        maxHeight: "200px !important",
    },
    paper: {
        fontSize: 14,
        position: "absolute",
        zIndex: 5,
        marginTop: theme.spacing(0),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2),
    },
});

class AutoSuggest extends React.Component {
    debouncedFetch = debounce(
        (value, callback) => {
            this.props.fetchOptions
                .apply(this, [value].concat([this.props.arg]))
                .then((suggestions) => callback(suggestions.map(this.props.renderOption)))
                .catch((e) => callback([]));
        },
        !!this.props.debounceTimeout ? this.props.debounceTimeout : 0
    );

    loadOptions = (value, callback) => {
        this.debouncedFetch(value, callback);
    };

    handleChange = (value) => {
        this.props.onChange(value && value.value);
    };

    render() {
        const { classes, theme } = this.props;

        const selectStyles = {
            input: (base) => ({
                ...base,
                color: theme.palette.text.primary,
                "& input": {
                    font: "inherit",
                },
            }),
        };

        return (
            <div className={classes.root}>
                <AsyncSelect
                    key={this.props.arg}
                    label={this.props.label}
                    helperText={this.props.helperText}
                    classes={classes}
                    styles={selectStyles}
                    optionIdentifier={this.props.optionIdentifier}
                    components={{
                        Control,
                        MenuList,
                        Menu,
                        NoOptionsMessage,
                        Option,
                        SingleValue,
                        ValueContainer,
                        ClearIndicator: ClearIndicator,
                        DropdownIndicator: DropdownIndicator,
                        IndicatorSeparator: NoComponent,
                        IndicatorsContainer,
                    }}
                    value={
                        (this.props.value || this.props.value === 0) &&
                        this.props.renderOption(this.props.value)
                    }
                    defaultOptions
                    loadOptions={this.loadOptions.bind(this)}
                    onChange={this.handleChange.bind(this)}
                    onBlur={this.props.onBlur}
                    placeholder=""
                    isClearable
                    isDisabled={this.props.disabled}
                    error={this.props.error}
                />
            </div>
        );
    }
}

const ClearIndicator = (props) => {
    return (
        <div {...props.innerProps} className={props.selectProps.classes.indicator}>
            {/* {props.children || <CrossIcon />} */}
            {props.children || <CrossIcon />}
        </div>
    );
};

const DropdownIndicator = (props) => {
    return (
        <div {...props.innerProps} className={props.selectProps.classes.indicator}>
            {props.children || <DownChevron />}
        </div>
    );
};

function NoComponent() {
    return null;
}

function IndicatorsContainer(props) {
    return <div className={props.selectProps.classes.indicatorsContainer}>{props.children}</div>;
}

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

function Control(props) {
    return (
        <TextField
            label={props.selectProps.label}
            error={props.selectProps.error}
            helperText={props.selectProps?.helperText}
            className={props.selectProps.isDisabled ? props.selectProps.classes.inputDisabled : ""}
            variant="filled"
            fullWidth
            InputLabelProps={{
                shrink: props.isFocused || props.hasValue,
            }}
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
        />
    );
}

function Option(props) {
    return (
        <MenuItem
            ref={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight:
                    props.isSelected ||
                    isSelected(props.data, props.getValue(), props.selectProps.optionIdentifier)
                        ? 500
                        : 400,
                paddingLeft: "8px",
            }}
            title={
                props.children && typeof props.children === "string" && !props.data.hint
                    ? props.children
                    : null
            }
            {...props.innerProps}>
            <Fragment>
                {props.data.icon}
                <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{props.children}</div>
                {props.data.hint && (
                    <Box color="text.secondary" marginLeft={"auto"} width={24} height={24}>
                        <Tooltip title={props.data.hint} placement="top">
                            <HelpOutlineIcon />
                        </Tooltip>
                    </Box>
                )}
            </Fragment>
        </MenuItem>
    );
}

function isSelected(option, valueArray, optionIdentifier) {
    const value = valueArray && valueArray[0];

    if (option === value) {
        return true;
    }

    return (
        value &&
        option &&
        optionIdentifier &&
        optionIdentifier(option.value) === optionIdentifier(value.value)
    );
}

function SingleValue(props) {
    return (
        <Typography
            className={"autoSuggest--valueContainer " + props.selectProps.classes.singleValue}
            title={props.children && typeof props.children === "string" ? props.children : null}
            {...props.innerProps}>
            {props.data.icon}
            {props.children}
        </Typography>
    );
}

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MenuList(props) {
    return (
        <components.MenuList {...props} className={props.selectProps.classes.menuList}>
            {props.children}
        </components.MenuList>
    );
}

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

export default withStyles(styles, { withTheme: true })(AutoSuggest);
