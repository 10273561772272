import React, { useState } from 'react'
import { createLocation } from 'api/api';

import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
    dialog: {
        padding: theme.spacing(1),
        overflowY: "visible",
    },
    content: {
        overflowY: "visible",
    }
}));

function CreateLocation({ open, handleClose, handleSubmit }) {
    const classes = useStyles();
    const [formDisabled, setFormDisabled] = useState(true);
    const [newLocation, setNewLocation] = useState({
        key: "",
        title: "",
        domain: "",
        shortcut: "",
    });

    const onChange = (event) => {
        const { id, value } = event.target;

        setNewLocation((prevState) => {
            const newLoc = {
                ...prevState,
                [id]: value,
            };

            setFormDisabled(Object.keys(newLoc).some((key) => newLoc[key].length < 1));

            return newLoc;
        })
    }

    const onSubmit = (event) => {
        event.preventDefault();

        createLocation(
            newLocation.key,
            newLocation.title,
            newLocation.domain,
            newLocation.shortcut
        ).then(() => {
            handleSubmit();
            handleClose();
        })
    }

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={open}
            onClose={handleClose}
            PaperProps={{
                className: classes.dialog
            }}
        >
            <DialogTitle>
                Create Location
            </DialogTitle>
            <form onSubmit={onSubmit}>
                <DialogContent className={classes.content}>
                    <Grid container spacing={2} direction='column'>
                        <Grid item>
                            <TextField
                                id="key"
                                label="Key"
                                variant="outlined"
                                fullWidth
                                error={!newLocation.key}
                                value={newLocation.key}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                id="title"
                                label="Title"
                                variant="outlined"
                                fullWidth
                                error={!newLocation.title}
                                value={newLocation.title}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                id="domain"
                                label="Domain"
                                variant="outlined"
                                fullWidth
                                error={!newLocation.domain}
                                value={newLocation.domain}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                id="shortcut"
                                label="Shortcut"
                                variant="outlined"
                                fullWidth
                                error={!newLocation.shortcut}
                                value={newLocation.shortcut}
                                onChange={onChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={handleClose}
                    >
                        cancel
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={formDisabled}
                    >
                        create
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default CreateLocation;
