import React from "react";
import { useLocation, Link as RouterLink } from "react-router-dom";

import Link from "@material-ui/core/Link";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

export default function MenuLink(props) {
    const { to, handler, icon, name } = props;
    const location = useLocation();

    return (
        <Link
            component={RouterLink}
            to={to}
            underline="none"
            className={location.pathname === to ? "active" : undefined}
            onClick={handler || (() => {})}>
            <ListItem button>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={name} />
            </ListItem>
        </Link>
    );
}
