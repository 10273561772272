import React from "react";
import { useLocation } from "react-router-dom";

import CenteredView from "../centeredView/CenteredView";
import MenuLink from "containers/components/menuItem/MenuLink";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import HomeIcon from "@material-ui/icons/Home";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

function Unauthorized() {
    const location = useLocation();

    if (!location.state?.unauthorized) {
        return <Redirect to="/" />;
    }

    return (
        <CenteredView>
            <Grid container direction="column" alignItems="center">
                <Grid item>
                    <Typography variant="h6" component="span">
                        {"Insufficient permissions to access this page."}
                    </Typography>
                </Grid>
                <Grid item>
                    <MenuLink to="/" name="Home" icon={<HomeIcon />} />
                </Grid>
            </Grid>
        </CenteredView>
    );
}

export default Unauthorized;
