import { createContext } from "react";

const SelectedThemeContext = createContext({ toggleTheme: () => {} });

const COLORS = {
    ttmsBlue: "#183EC9",
    ttmsLightBlue: "#A0C4FF",
};

const createBaseThemeAttributes = (mode) => ({
    typography: {
        useNextVariants: true,
    },
    palette: {
        type: mode,

        ...(mode === "light"
            ? {
                  primary: { main: COLORS.ttmsBlue },
                  secondary: { main: COLORS.ttmsBlue },
                  background: {
                      default: "#ffffff",
                  },
              }
            : {
                  primary: { main: COLORS.ttmsLightBlue },
                  secondary: { main: COLORS.ttmsLightBlue },
              }),
    },
});

export { createBaseThemeAttributes, SelectedThemeContext };
